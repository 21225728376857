// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/moderations',
    name: 'moderations-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/moderations/Index.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'moderation',
    },
  },
]

export default formTable
