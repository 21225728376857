// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/tutorials',
    name: 'tutorials-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/tutorials/Index.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'tutorials',
    },
  },
  {
    path: '/tutorials/create',
    name: 'tutorials-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/tutorials/form/Tutorials.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'tutorials',
    },
  },
  {
    path: '/tutorials/view',
    name: 'tutorials-view',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/tutorials/form/View.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'tutorials',
    },
  },
  {
    path: '/tutorials/update',
    name: 'tutorials-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/tutorials/form/Edit.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'tutorials',
    },
  },
]

export default formTable
