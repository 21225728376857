<template>
  <div
    :style="login ? 'font-size: 12px;' : ''"
    class="d-flex justify-space-between"
    :class="login ? 'text-center' : ''"
  >
    <span>COPYRIGHT &copy; {{ new Date().getFullYear() }}
      <a
        href="#"
        class="text-decoration-none"
      > {{ $t('footer.powered') }} {{ $store.state.app.app_name }}</a>.
      <br v-if="login" />
      {{ $t('footer.allRights') }}.
    </span>
  </div>
</template>
<script>
export default {
  props: {
    login: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
