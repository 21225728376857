// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/categories/list',
    name: 'categorias-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/Categorias.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_categorias_hotels',
    },
  },
]

export default formTable
