// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/newletters/list',
    name: 'newletters-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/newletters/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'newletters',
    },
  },
  {
    path: '/newletters/send',
    name: 'newletters-send',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/newletters/form/Newletters.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'newletters',
    },
  },
]

export default formTable
