// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/json/airport',
    name: 'json-airport-import',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/json/Airport.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'import_airport',
    },
  },
]

export default formTable
