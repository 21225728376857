// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/servicios/list',
    name: 'servicios-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/Servicios.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_servicios_hotels',
    },
  },
]

export default formTable
