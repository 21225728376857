// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/marca-cars/list',
    name: 'marca-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/Marca.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_marca_cars',
    },
  },
]

export default formTable
