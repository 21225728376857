import creations from './cotizador/creations'
import schedule from './cotizador/schedule'
import disponibilidadFlights from './cotizador/disponibilidadFlights'
import selectedFlights from './cotizador/selectedFlights'
import selectedCars from './cotizador/selectedCars'
import selectedHotels from './cotizador/selectedHotels'
import reservas from './reservas/creations'
import carShop from './carShop/creations'
import tarifarios from './tarifarios/creations'

export default [
  ...creations,
  ...schedule,
  ...disponibilidadFlights,
  ...selectedFlights,
  ...selectedCars,
  ...selectedHotels,
  ...reservas,
  ...carShop,
  ...tarifarios,
]
