// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // TIPO DE AFILIADO
  {
    path: '/currencies/list',
    name: 'nomenclators-currencies-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/currencies/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nomenclators_currencies',
    },
  },
  {
    path: '/currencies/update',
    name: 'nomenclators-currencies-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/currencies/form/Update.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nomenclators_currencies',
    },
  },
]

export default formTable
