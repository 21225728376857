<template>
  <fragment>
    <v-menu
      v-if="carItems.length > 0"
      offset-y
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          color="primary"
          :content="carItems.length"
        >
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="getListCar"
          >
            {{ icons.mdiCartOutline }}
          </v-icon>
        </v-badge>
      </template>
    </v-menu>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import { mdiCartOutline } from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      user: {},
      cars: [],
      icons: {
        mdiCartOutline,
      },
      carItems: [],
    }
  },
  computed: {
    ...mapState({
      // carItems: state => state.app.carItems,
    }),
  },
  created() {
    // eslint-disable-next-line no-undef
    this.$echo.channel('change_cart').listen('.change_cart', data => {
      this.carItems = data.data
    })
    this.getCar()
    this.profile()
  },
  methods: {
    ...mapMutations(['updateItemToCar']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          // this.getCar()
        })
    },
    getCar() {
      /* if (localStorage.getItem('car-shop') !== null) {
        const listCars = JSON.parse(localStorage.getItem('car-shop'))
        const listResult = []
        listCars.forEach(element => {
          if (element.user_id === this.user.id) {
            listResult.push(element)
          }
        })
        this.updateItemToCar(listResult)
      } */

      this.axios
        .post('cart', { items: 'all' }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === true) {
            this.carItems = response.data.data
          }
        })
    },
    getListCar() {
      this.$router.push({ name: 'car-shop-items' })
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
