// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/cars/list',
    name: 'cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/cars/Cars.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'auto',
    },
  },
  {
    path: '/cars/update',
    name: 'cars-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/cars/form/Cars.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'auto',
    },
  },
  {
    path: '/cars/update-crop',
    name: 'cars-update-crop',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/cars/utils/CropsImage.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'auto',
    },
  },
  {
    path: '/cars/upload-lote',
    name: 'cars-upload-lote',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/cars/form/CarsUploadLote.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'auto',
    },
  },
  {
    path: '/cars/export',
    name: 'cars-export',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/cars/exportations/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'auto',
    },
  },
]

export default formTable
