// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/hotels/list',
    name: 'hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/hotels/Hotels.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'hotel',
    },
  },
  {
    path: '/hotels/update',
    name: 'hotels-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/hotels/form/Hotels.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'hotel',
    },
  },
  {
    path: '/hotels/update-crop',
    name: 'hotels-update-crop',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/hotels/utils/CropsImage.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'hotel',
    },
  },
  {
    path: '/hotels/upload-lote',
    name: 'hotels-upload-lote',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/hotels/form/HotelsUploadLote.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'hotel',
    },
  },
  {
    path: '/hotels/upload-lote-services',
    name: 'hotels-upload-lote-services',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/hotels/form/HotelsUploadLoteServices.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'hotel',
    },
  },
  {
    path: '/hotels/upload-lote-restore',
    name: 'hotels-upload-lote-restore',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/hotels/form/HotelsUploadLoteRestore.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'hotel',
    },
  },
  {
    path: '/hotels/export',
    name: 'hotels-export',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/hotels/exportations/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'hotel',
    },
  },
]

export default formTable
