// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/tag-img/list',
    name: 'tag-img-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/TagImg.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_tag_imagen_hotels',
    },
  },
  {
    path: '/tag-img/update',
    name: 'tag-img-hotels-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/form/TagImg.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_tag_imagen_hotels',
    },
  },
]

export default formTable
