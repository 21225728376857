<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import { useRouter } from '@core/utils'
// eslint-disable-next-line import/no-unresolved
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
// eslint-disable-next-line import/no-unresolved
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
// eslint-disable-next-line import/no-unresolved
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
// eslint-disable-next-line import/no-unresolved
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh
// eslint-disable-next-line import/no-unresolved
import useDynamicVh from '@core/utils/useDynamicVh'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameProject: process.env.VUE_APP_PROJ_NAME,
    }
  },
  created() {
    this.getFavicon()
  },
  methods: {
    getFavicon() {
      this.axios.get('configuration-view/data-general').then(res => {
        if (res.data.success) {
          this.model = res.data.data.data.data
          if (this.model.favicon) {
            const favicon = document.getElementById('favicon')
            favicon.href = `${this.rutaPublic}${this.model.favicon}`
          }

          if (this.model.name) {
            const ppp = 'Panel de Administración / '
            document.title = this.nameProject === 'wwwww'
              ? `${ppp}${this.model.name}, plataforma de viajes`
              : `${ppp}${this.model.name}`
          }
        }
      })
    },
  },
}
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
