// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/oficinas-cars/list',
    name: 'oficinas-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/Oficinas.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_oficina_renta_cars',
    },
  },
  {
    path: '/oficinas-cars/update',
    name: 'oficinas-cars-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/form/Oficinas.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_oficina_renta_cars',
    },
  },
  {
    path: '/oficinas-cars/import',
    name: 'oficinas-cars-import',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/form/OficinasUploadLote.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_oficina_renta_cars',
    },
  },
]

export default formTable
