// eslint-disable-next-line import/no-unresolved
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import AppModule from './app'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    appConfig: appConfigStoreModule,
    app: AppModule,
  },
})

export default store
