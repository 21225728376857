// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/caracteristicas-cars/list',
    name: 'caracteristicas-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/Caracteristicas.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_caracteristica_cars',
    },
  },
]

export default formTable
