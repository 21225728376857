// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/operador/list',
    name: 'operador-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/Operador.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_operador_hotels',
    },
  },
  {
    path: '/operador/update',
    name: 'operador-hotels-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/form/Operador.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_operador_hotels',
    },
  },
]

export default formTable
