// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // SETTINGS
  {
    path: '/chatbot/settings',
    name: 'chatbot-settings',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/chatbot/Settings.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'chatbot_setting',
    },
  },

  // MESSAGES AUTOMATIC
  {
    path: '/chatbot/messages',
    name: 'chatbot-messages-auto',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/chatbot/Messages.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'chatbot_messages_auto',
    },
  },
  {
    path: '/chatbot/message/update',
    name: 'chatbot-messages-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/chatbot/form/MessageUpdate.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'chatbot_messages_auto',
    },
  },

  // CONVERSACIONES
  {
    path: '/chatbot/talkings',
    name: 'chatbot-talkings',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/chat/admin/Chat.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'chatbot_talkings',
    },
  },
  {
    path: '/chatbot/talkings/archived',
    name: 'chatbot-talkings-archived',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/chat/admin/ChatArchived.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'chatbot_talkings',
    },
  },
]

export default formTable
