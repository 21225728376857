// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // PERMISOS
  {
    path: '/access/permisos',
    name: 'permisos-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/Permisos.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'permisos',
    },
  },

  // ROLES
  {
    path: '/access/roles',
    name: 'roles-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/Roles.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'roles',
    },
  },
  {
    path: '/access/roles/update',
    name: 'role-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/form/Role.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'roles',
    },
  },

  // PERFILES DE USER
  {
    path: '/access/perfil-afiliado',
    name: 'perfil-afiliado-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/PerfilAfiliado.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'perfil_afiliados',
    },
  },
  {
    path: '/access/perfil-afiliado/update',
    name: 'perfil-afiliado-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/form/Perfil.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'perfil_afiliados',
    },
  },

  // USER
  {
    path: '/access/users',
    name: 'users-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/Users.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'users',
    },
  },
  {
    path: '/access/users/update',
    name: 'user-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/form/User.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'users',
    },
  },
]

export default formTable
