// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // TIPO DE AFILIADO
  {
    path: '/tipo-afiliado/list',
    name: 'tipo-afiliado-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/tipoAfiliado/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'tipo_afiliado',
    },
  },
  {
    path: '/tipo-afiliado/update',
    name: 'tipo-afiliado-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/tipoAfiliado/form/Update.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'tipo_afiliado',
    },
  },
]

export default formTable
