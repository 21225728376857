// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/selected-cars',
    name: 'selected-cotizador-cars',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/booking/cotizador/selected/cars/Index.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'cotizador',
    },
  },

  /* {
    path: '/success-cotitation',
    name: 'success-cotitation',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/messages/SuccessCotization.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'cotizador',
    },
  }, */
]

export default formTable
