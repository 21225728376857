import Vue from 'vue'
import moment from 'moment'

require('moment/locale/es')

Vue.prototype.moment = moment

Vue.use(require('vue-moment'), {
  moment,
})
