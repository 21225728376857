// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/stop-sale/list',
    name: 'stopSale-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/stopSale/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'stopSale',
    },
  },
  {
    path: '/stop-sale/update-flight',
    name: 'stopSale-update-flight',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/stopSale/form/UpdateFlight.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'stopSale',
    },
  },
  {
    path: '/stop-sale/view-flight',
    name: 'stopSale-view-flight',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/stopSale/form/ViewFlight.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'stopSale',
    },
  },
]

export default formTable
