// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/age/list',
    name: 'edad-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/Edad.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_edad_hotels',
    },
  },
]

export default formTable
