// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/dimensiones-equipaje-flights/list',
    name: 'dimensiones-equipaje-flights-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/flights/Dimensiones.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_dimensiones_flights',
    },
  },
]

export default formTable
