// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/pays-emit/list',
    name: 'pays-emitidos-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/paysEmitidos/Pays.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'pays_emitidos',
    },
  },
]

export default formTable
