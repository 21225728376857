import Vue from 'vue'
import SocketClient from 'socket.io-client'
import VueEcho from 'vue-echo-laravel'
import Echo from 'laravel-echo'

window.io = SocketClient

const EchoInstance = new Echo({
  broadcaster: 'socket.io',
  host: `${process.env.VUE_APP_URL_SOCKET}:${process.env.VUE_APP_URL_SOCKET_PORT}`,
  upgrade: false,
  transports: ['websocket'],
})

Vue.use(VueEcho, EchoInstance)
