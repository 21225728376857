import creations from './creations'
import newletters from '../newletters/creations'
import stopSale from '../stopSale/creations'
import offertsEBB from '../offertsEBB/creations'
import exportations from '../exportations/creations'
import contabilidad from '../contability/creations'
import ctasXC from '../cuentasXC/creations'
import ctasXP from '../cuentasXP/creations'
import paysEmitidos from '../paysEmitidos/creations'
import currencyExchange from '../currencyExchange/creations'

export default [
  ...creations,
  ...newletters,
  ...stopSale,
  ...offertsEBB,
  ...exportations,
  ...contabilidad,
  ...ctasXC,
  ...ctasXP,
  ...paysEmitidos,
  ...currencyExchange,
]
