// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const charts = [
  {
    path: '/profile',
    name: 'profile',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/UserView.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
    },
  },
]

export default charts
