// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/flights/list',
    name: 'flights-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/flights/Flights.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'vuelo',
    },
  },
  {
    path: '/flights/update',
    name: 'flights-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/products/flights/form/Flights.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'vuelo',
    },
  },
]

export default formTable
