// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/user-register/list',
    name: 'user-register-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user_register/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'afiliados',
    },
  },
  {
    path: '/user-register/insert',
    name: 'user-register-insert',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user_register/form/Insert.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'afiliados',
    },
  },
  {
    path: '/user-register/update',
    name: 'user-register-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user_register/form/Update.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'afiliados',
    },
  },
  {
    path: '/user-active/:token',
    name: 'user-register-active',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user_register/form/ActiveRegister.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/user-active-message',
    name: 'user-active-success',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/messages/SuccessActive.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/user-registre',
    name: 'user-registre-success',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/messages/SuccessRegistre.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/registro/:token',
    name: 'user-registro',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user_register/form/EditRegister.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/user-company',
    name: 'configuration-general-user-company',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/company/identificador/General.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
    },
  },
]

export default formTable
