// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/airport-flights/list',
    name: 'airport-flights-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/flights/Airports.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'airports',
    },
  },
  {
    path: '/airport-flights/update',
    name: 'airport-flights-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/flights/form/Airport.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'airports',
    },
  },
]

export default formTable
