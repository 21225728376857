// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/ctas-x-pagar/list',
    name: 'ctas-x-pagar-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/ctasXP/CtasXP.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'ctas_x_pagar',
    },
  },
]

export default formTable
