// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // TIPO DE AFILIADO
  {
    path: '/countries/list',
    name: 'nomenclators-countries-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/countries/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nomenclators_countries',
    },
  },
  {
    path: '/countries/update',
    name: 'nomenclators-countries-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/countries/form/Update.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nomenclators_countries',
    },
  },
]

export default formTable
