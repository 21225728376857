// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/reservations',
    name: 'reservations',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/booking/reservas/Reservas.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'reservas',
    },
  },
  {
    path: '/reservations/show',
    name: 'reservations-show',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/booking/reservas/form/Show.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'reservas',
    },
  },
  {
    path: '/reservations/update',
    name: 'reservations-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/booking/reservas/form/Update.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'reservas',
    },
  },

  {
    path: '/cotizations/update',
    name: 'cotizations-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/booking/reservas/form/UpdateCotization.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'reservas',
    },
  },
  {
    path: '/cotization/:code',
    name: 'cotizations-view-front',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/utils/viewPdf/Cotizations.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

export default formTable
