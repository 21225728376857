// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/combustible-cars/list',
    name: 'combustible-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/Combustible.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_combustible_cars',
    },
  },
]

export default formTable
