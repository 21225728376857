// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/car-items',
    name: 'car-shop-items',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/booking/car-shop/CarShopList.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'cotizador',
    },
  },
  {
    path: '/car-item/update',
    name: 'car-shop-item-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/booking/car-shop/edit/Index.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'cotizador',
    },
  },
]

export default formTable
