// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/agentes/list',
    name: 'agentes-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/Agentes.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'agentes',
    },
  },
  {
    path: '/agentes/update',
    name: 'agentes-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/form/Agente.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'agentes',
    },
  },
]

export default formTable
