import { Ability } from '@casl/ability'
import { initialAbility } from './config'

// Habilidad de lectura desde localStorage
// * Maneja la obtención automática de habilidades anteriores si el usuario ya ha iniciado sesión
// ? Puede actualizar esto si almacena las habilidades del usuario en un lugar más seguro
// ! Cualquiera puede actualizar localStorage, así que tenga cuidado y actualice esto.
const existingAbility = JSON.parse(localStorage.getItem('userAbility'))

export default new Ability(existingAbility || initialAbility)
