// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/markups/list',
    name: 'markups-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/markups/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'markups',
    },
  },
  {
    path: '/markups/update-flight',
    name: 'markups-update-flight',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/markups/form/UpdateFlight.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'markups',
    },
  },
  {
    path: '/markups/view-flight',
    name: 'markups-view-flight',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/administration/markups/form/ViewFlight.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'markups',
    },
  },
]

export default formTable
