<template>
  <v-menu
    v-if="!isLoading && exchanges.length > 0"
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-cash-multiple</v-icon>
      </v-btn>
    </template>

    <!-- Options List @change="updateActiveLocale" -->
    <v-list>
      <v-list-item-group>
        <v-list-item disabled>
          <v-list-item-title>{{ `${$t('lbl.tasa')}: ${today}` }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <!--<v-list-item>
          <v-list-item-title>{{ currency_base }}</v-list-item-title>
        </v-list-item>-->
        <v-list-item
          v-for="(exchange, ind) in exchanges"
          :key="ind"
          :value="ind"
          disabled
        >
          <v-list-item-title>{{ exchange.currency_symbol }} {{ exchange.currency_code }}: {{ exchange.value | number('0.0000') }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      exchanges: [],
      currency_base: null,
      today: null,
    }
  },
  computed: {
    ...mapState({
      currency_code: state => state.app.currency_code,
      currency_symbol: state => state.app.currency_symbol,
    }),
  },

  created() {
    this.getExchanges()
  },
  methods: {
    ...mapMutations([
      'setCurrencyCode',
      'setCurrencySymbol',
    ]),
    setChangeCurrency(currency) {
      localStorage.setItem('currency_code', currency.code)
      localStorage.setItem('currency_symbol', currency.symbol)

      this.setCurrencyCode(localStorage.getItem('currency_code'))
      this.setCurrencySymbol(localStorage.getItem('currency_symbol'))

      /* PARA Q EDITE A LA VES SU PERFIL
      const json = {
        currency_id: currency.id,
        currency_code: this.currency_code,
        currency_symbol: this.currency_symbol,
      }
      this.axios
        .post('profile/update-data', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) { }
        }) */
    },
    getExchanges() {
      this.axios
        .post('exchange_currency/last-update', { all: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.today = res.data.data.today
            this.currency_base = res.data.data.currency_base
            this.exchanges = res.data.data.exchanges
            localStorage.setItem('exchange_currency', JSON.stringify(res.data.data))
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
