// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/tag-img-cars/list',
    name: 'tag-img-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/TagImg.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_tag_imagen_hotels',
    },
  },
  {
    path: '/tag-img-cars/update',
    name: 'tag-img-cars-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/form/TagImg.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_tag_imagen_hotels',
    },
  },
]

export default formTable
