// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/flights-contrate/list',
    name: 'flights-contrate-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/flights/ContrateFlights.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_flight',
    },
  },
  {
    path: '/flights-contrate/update',
    name: 'flights-contrate-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/flights/form/ContrateFlights.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_flight',
    },
  },
]

export default formTable
